import { Web3Provider } from '@ethersproject/providers';
import { Web3ReactProvider } from '@web3-react/core';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getLibrary(provider: any) {
    const library = new Web3Provider(provider);
    library.pollingInterval = 12000;
    return library;
}

const WrappedWeb3ReactProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
    return <Web3ReactProvider getLibrary={getLibrary}>{children}</Web3ReactProvider>;
};

const wrapWithWeb3ReactProvider = ({ element }: { element: JSX.Element }): JSX.Element => {
    return <WrappedWeb3ReactProvider>{element}</WrappedWeb3ReactProvider>;
};

export default wrapWithWeb3ReactProvider;
