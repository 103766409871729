// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-template-details-tsx": () => import("./../../../src/components/TemplateDetails.tsx" /* webpackChunkName: "component---src-components-template-details-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-details-tsx": () => import("./../../../src/pages/details.tsx" /* webpackChunkName: "component---src-pages-details-tsx" */),
  "component---src-pages-dev-console-tsx": () => import("./../../../src/pages/devConsole.tsx" /* webpackChunkName: "component---src-pages-dev-console-tsx" */),
  "component---src-pages-dev-examples-tsx": () => import("./../../../src/pages/devExamples.tsx" /* webpackChunkName: "component---src-pages-dev-examples-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */)
}

